import { Invoice } from '@amaui/api-utils';

import { BaseService } from './base';

class InvoiceService extends BaseService<Invoice> {

  public constructor() {
    super('invoices');
  }

}

export default new InvoiceService();
