import React from 'react';
import { Helmet } from 'react-helmet';
import * as Sentry from '@sentry/react';

import { getID } from '@amaui/utils';
import { Line, Reset, useMediaQuery } from '@amaui/ui-react';
import { classNames, style, useAmauiTheme } from '@amaui/style-react';

import { AmauiService, StorageService } from 'services';
import config from 'config';
import { ErrorBoundary } from 'ui';
import Routes from './Routes';

const useStyle = style(theme => ({
  '@p': {
    '*': {
      fontOpticalSizing: 'auto',

      '&::selection': {
        color: theme.methods.palette.color.value('primary', 10),
        backgroundColor: theme.methods.palette.color.value('primary', 80)
      },

      '&[contenteditable]:empty:before': {
        color: 'inherit',
        content: 'attr(data-placeholder)',
        display: 'block',
        fontFamily: 'inherit',
        fontStyle: 'inherit',
        fontWeight: 'inherit',
        opacity: 0.5,
        fontSize: '1rem'
      }
    },

    body: {
      fontFamily: theme.typography.font_family.secondary,
      color: theme.methods.palette.color.value('primary', 10),
      backgroundColor: theme.palette.light ? theme.palette.color.primary[99] : theme.palette.color.primary[5],
      height: '100vh',
      overflow: 'hidden',

      '& .amaui-app-themes-App-test': {
        '& .amaui-Button-root, & .amaui-Icon-root': {
          pointerEvents: 'none'
        }
      },

      '&::before': {
        content: "''",
        background: '#dcfefb',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        position: 'absolute',
        backgroundImage: 'radial-gradient(circle at top left, hsl(60deg 100% 50%), transparent 400px), radial-gradient(circle at top right, #ffb200, transparent 30%), radial-gradient(at bottom left, #00ffe9, transparent 40%), radial-gradient(at bottom right, #9b00ff, transparent 400px), radial-gradient(at 60% 57%, #00ff99, transparent 44%)',
        opacity: 0.1
      },

      '& .amaui-overflow-y': {
        flex: '1 1 auto',
        height: 0,
        overflow: 'hidden auto'
      },

      '& .amaui-overflow-x': {
        paddingBlock: 6,
        overflow: 'auto hidden'
      },

      '& .amaui-Label-helper-text, & .amaui-TextField-helper-text': {
        ...theme.typography.values.b2
      },

      '& .amaui-Snackbar-root': {
        padding: '10px 10px 10px 32px',
        borderRadius: 0,

        '& .amaui-Snackbar-primary': {
          '& > .amaui-Type-root': {
            fontSize: '1rem',
            fontWeight: 300
          }
        }
      },

      '& .amaui-DateRangePicker-root': {
        '& .amaui-TextField-input, & .amaui-TextField-label': {
          fontSize: '1rem',
          fontWeight: 200
        },

        '& .amaui-TextField-legend': {
          fontSize: '0.75rem',
          fontWeight: 200
        }
      },

      '& .amaui-TextField-root': {
        '& .amaui-TextField-input, & .amaui-TextField-label': {
          fontSize: '1rem',
          fontWeight: 200
        },

        '& .amaui-TextField-legend': {
          fontSize: '0.75rem',
          fontWeight: 200
        }
      },

      '& .amaui-Select-root': {
        '& .amaui-Select-input, & .amaui-TextField-label': {
          fontSize: '1rem',
          fontWeight: 200
        },

        '& .amaui-TextField-legend': {
          fontSize: '0.75rem',
          fontWeight: 200
        },

        '& .amaui-ListItem-text-primary': {
          whiteSpace: 'nowrap'
        }
      },

      '& .amaui-DatePicker-root:not(.amaui-full-width), & .amaui-TextField-wrapper:not(.amaui-full-width), & .amaui-TextField-root:not(.amaui-full-width), & .amaui-Select-wrapper:not(.amaui-full-width), & .amaui-Select-root:not(.amaui-full-width), & .amaui-TextField-root:not(.amaui-full-width), & .amaui-AutoCompleteGooglePlaces-root:not(.amaui-full-width), & .amaui-AutoComplete-wrapper:has(.amaui-AutoCompleteGooglePlaces-root):not(.amaui-full-width), & .amaui-AutoCompleteCurrency-root:not(.amaui-full-width), & .amaui-AutoComplete-wrapper:has(.amaui-AutoCompleteCurrency-root):not(.amaui-full-width)': {
        width: theme.input,
        height: 50,
        flex: '0 0 auto'
      },

      '& .amaui-AutoComplete-wrapper.amaui-full-width': {
        '& .amaui-AutoComplete-root': {
          width: '100%'
        }
      },

      '& .amaui-Type-root': {
        '&.amaui-Type-version-b1, &.amaui-Type-version-b2, &.amaui-Type-version-b3': {
          fontWeight: 200
        }
      },

      '& .amaui-Modal-surface': {
        '&.amaui-Surface-root': {
          borderRadius: 0,
          background: theme.palette.color.primary[theme.palette.light ? 99 : 5]
        }
      },

      '& .amaui-Modal-background:not(.amaui-Modal-background-invisible)': {
        background: theme.methods.palette.color.colorToRgb(theme.methods.palette.color.value('default', 10), theme.palette.light ? 20 : 40)
      },

      '& .amaui-Tabs-root.amaui-Surface-root': {
        background: 'transparent',
        minHeight: 'unset'
      },

      '& .amaui-Page-root': {
        maxWidth: 'unset'
      },

      '& .amaui-mention': {
        ...theme.typography.values.l2,

        fontSize: 'inherit',
        fontWeight: 400,
        cursor: 'pointer',
        userSelect: 'none',
        color: theme.methods.palette.color.value('primary', 30)
      },

      '& .amaui-ModalHeader-root': {
        paddingBottom: 0
      },

      '& .amaui-Tooltip-name-root': {
        '& > .amaui-DateTimePicker-main, & > .amaui-DatePicker-main, & > .amaui-TimePicker-main, & > .amaui-Calendar-root': {
          boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.04)'
        },

        '& > .amaui-List-root': {
          // maxWidth: 'unset',
          // width: '100%'
        }
      },

      '& .amaui-SmartTextField-root[data-placeholder="Description"]': {
        margin: '0 0 20px',
        overflow: 'hidden auto',

        ...theme.typography.values.b1
      }
    },

    a: {
      color: 'var(--amaui-palette-color-primary-30)'
    },

    '.amaui-logo-amaui': {
      color: (theme.palette.color as any).amaui.main,
      fill: (theme.palette.color as any).amaui.main
    },

    '.amaui-logo-personal-trainer': {
      color: (theme.palette.color as any)['personal-trainer'].main,
      fill: (theme.palette.color as any)['personal-trainer'].main
    },

    '.amaui-logo-website': {
      color: (theme.palette.color as any).website.main,
      fill: (theme.palette.color as any).website.main
    },

    '.amaui-logo-task': {
      color: (theme.palette.color as any).task.main,
      fill: (theme.palette.color as any).task.main
    },

    '.amaui-logo-note': {
      color: (theme.palette.color as any).note.main,
      fill: (theme.palette.color as any).note.main
    },

    '.amaui-logo-url-shortener': {
      color: (theme.palette.color as any)['url-shortener'].main,
      fill: (theme.palette.color as any)['url-shortener'].main
    },

    '.amaui-logo-chat': {
      color: (theme.palette.color as any).chat.main,
      fill: (theme.palette.color as any).chat.main
    },

    '.amaui-logo-contact': {
      color: (theme.palette.color as any).contact.main,
      fill: (theme.palette.color as any).contact.main
    },

    '.amaui-logo-location': {
      color: (theme.palette.color as any).location.main,
      fill: (theme.palette.color as any).location.main
    }
  },

  '@media only screen and (max-width: 479px)': {
    '.amaui-Select-wrapper, .amaui-Select-root': {
      width: '100%'
    }
  },

  root: {
    height: '100vh',
    position: 'relative',
    zIndex: 14
  }
}), { name: 'Root' });

const Main = () => {
  const { classes } = useStyle();

  const theme = useAmauiTheme();
  const light = useMediaQuery('(prefers-color-scheme: light)');

  const update = async (version = 'light', value: any = true) => {
    switch (version) {
      case 'light':
        theme.updateWithRerender({
          palette: {
            light: value
          }
        });

        StorageService.add('light', value);

        theme.inited = true;

        break;

      default:
        break;
    }
  };

  const init = React.useCallback(async () => {
    // device ID
    let deviceID = StorageService.get('device-id');

    if (!deviceID) {
      deviceID = getID();

      StorageService.add('device-id', deviceID);
    }

    AmauiService.init();

    if (config.value.production) {
      Sentry.init({
        dsn: config.value.services.sentry.dsn,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
          }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });
    }
  }, []);

  React.useEffect(() => {
    // init
    init();
  }, []);

  // theme
  React.useEffect(() => {
    if (theme.palette.light !== light) update('light', light);
  }, [light, theme]);

  return <>
    <Reset />

    <Helmet>
      <title>amaui</title>
      <link rel='manifest' href={`/assets/favicon/${theme.palette.light ? 'light' : 'dark'}/site.webmanifest`} />
      <meta name='msapplication-TileColor' content='#ffffff' />
      <meta name='apple-mobile-web-app-status-bar-style' content={theme.palette.light ? '#fafa00' : '#675C00'} />
      <meta name='theme-color' content={theme.palette.light ? '#fafa00' : '#675C00'} />
    </Helmet>

    <ErrorBoundary>
      <Line
        gap={0}

        direction='column'

        align='unset'

        justify='unset'

        fullWidth

        flex

        className={classNames([
          'amaui-App',

          classes.root
        ])}
      >
        <Routes />
      </Line>
    </ErrorBoundary>
  </>;
};

export default Main;
