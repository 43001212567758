import { Line, Type } from '@amaui/ui-react';

export const Element = (props: any) => {
  const {
    size = 'regular',

    children,

    ...other
  } = props;

  return (
    <Line
      align='center'

      justify='center'

      fullWidth

      {...other}
    >
      <Line
        gap={1}

        direction='column'

        align='center'

        justify='center'

        fullWidth

        style={{
          paddingBlock: 54
        }}
      >
        <img
          alt=''

          src='/assets/images/not-found.png'

          style={{
            width: '100%',
            maxWidth: 540
          }}
        />

        <Type
          version={size === 'large' ? 'h3' : size === 'regular' ? 't1' : 't2'}

          weight={300}

          align='center'
        >
          {children || 'No results found'}
        </Type>
      </Line>
    </Line>
  );
};

export default Element; 
